import styled from "styled-components"
import { Globals, ContentDistribution, ContentPosition } from "csstype"

const Flex = styled.div<{
  justifyContent?:
    | Globals
    | ContentDistribution
    | ContentPosition
    | "left"
    | "normal"
    | "right"
}>`
  max-width: 1440px;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-around"};
  margin: 0 auto;
`

export default Flex
