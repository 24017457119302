import React, { useRef } from "react"
import { Canvas, useFrame } from "react-three-fiber"
import glsl from "glslify"
import { ShaderMaterial } from "three"

const frag = glsl`
  precision highp float;

  uniform float time;
  varying vec2 vUv;

  #pragma glslify: noise = require('glsl-noise/simplex/3d');
  #pragma glslify: hsl2rgb = require('glsl-hsl2rgb');

  void main () {
    vec2 center = vUv - vec2(0.5, 0.5);

    // modify center's value by aspect ratio
    // center.x *= aspect;

    float n = noise(vec3(vUv.xy, time) / 2.0);

    vec3 color = hsl2rgb(
      sin(n * 5.0) * 0.19 + 0.63,
      0.7,
      0.5
    );

    float dist = length(center);
    float alpha = smoothstep(0.355, 0.345, dist);

    // gl_FragColor = vec4(vec3(n), 1.0);
    gl_FragColor = vec4(color, alpha);
  }
`

const vert = glsl`
  varying vec2 vUv;

  uniform float time;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const EvolvingPlane = () => {
  const uniforms = useRef({ time: { value: 0.0 } })
  const material = useRef<ShaderMaterial>(null)

  useFrame(({ clock }) => {
    if (material.current) {
      material.current.uniforms.time.value = clock.getElapsedTime()
    }
  })

  return (
    <mesh>
      <planeBufferGeometry attach="geometry" args={[10, 10]} />
      <shaderMaterial
        ref={material}
        attach="material"
        fragmentShader={frag}
        vertexShader={vert}
        uniforms={uniforms.current}
        transparent
      />
    </mesh>
  )
}

const Evolv = () => {
  return (
    <Canvas>
      <EvolvingPlane />
    </Canvas>
  )
}

export default Evolv
