import React, { FunctionComponent } from "react"

const Anchor: FunctionComponent<{ className?: string; fillColor?: string }> = ({
  className,
  fillColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id="objects"
      viewBox="0 0 254.48 314.69"
      style={{
        fill: fillColor ? fillColor : `#fff`,
      }}
    >
      <path d="M243.09 154c.7 22.05-39.2 55.65-39.2 60.9s11.55 6.3 11.55 6.3c-50.4 52.85-71.4 36.4-71.4 36.4-7-13.3-7.35-184.09-7.35-184.09l-9.1-5.51v-.4l-.35.21-.35-.21v.4l-9.1 5.53s-.35 170.79-7.35 184.09c0 0-21 16.45-71.4-36.4 0 0 11.55-1.05 11.55-6.3 0-6.75-39.85-40.34-39.2-60.9 0 0-21.69 49-3.85 73.85 0 0 4.9 3.5 14.35-1 0 0 29.22 57 105 87.2 0 .19.77.19.78 0 75.75-30.18 105-87.2 105-87.2 9.45 4.55 14.35 1 14.35 1C264.79 203 243.09 154 243.09 154z" />
      <path d="M127.24.5a37.1 37.1 0 1037.1 37.1A37.1 37.1 0 00127.24.5zm0 59.18a22.08 22.08 0 1122.08-22.08 22.09 22.09 0 01-22.08 22.08z" />
      <rect width="18" height="58.33" x="118.24" y="48.41" rx="8" />
      <path d="M161.24 101.57c0 4.42-4.17 8-9.32 8h-49.35c-5.15 0-9.33-3.58-9.33-8v-2c0-4.42 4.18-8 9.33-8h49.35c5.15 0 9.32 3.58 9.32 8z" />
    </svg>
  )
}

export default Anchor
