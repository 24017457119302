import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import SlideContent from "../components/SlideContent"
import ContactForm from "../components/ContactForm"
import Anchor from "../components/Icons/Anchor"
import { useState } from "react"
import RevealText from "../components/LoopText"
import Evolv from "../components/Evolv"
import BackgroundScroll from "../components/BackgroundScroll"

// import Flex from "../components/Flex"
// import ChainContent from "../components/ChainContent"
// import FeatureCard from "../components/FeatureCard"

const HeadingSecondary = styled.h2`
  line-height: 0.9;
  font-size: 100px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-bottom: 65px;
`

const WhoWeAre = styled.section`
  background: #0b1118;
  color: #fff;
  padding: 50px 0;
`

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const EntrepreneurCopy = styled.div`
  width: 90%;
  margin: 0 auto;

  font-size: 24px;
  p {
    margin: 50px 0 30px 0;

    strong {
      font-weight: 900;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      z-index: 100;
    }
  }
`

const BlockQuote = styled.blockquote`
  margin: 0;
  text-indent: -0.3em;
  quotes: "“" "”" "‘" "’";

  font-family: "Noto Serif", serif;

  @supports (hanging-punctuation: first) {
    text-indent: 0;
    hanging-punctuation: first;
  }

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`

const WhatWeDo = styled.section`
  background: #fff;
  color: #202f42;
  padding: 200px 0;
  position: relative;
`

const CreativeIntelligence = styled.div`
  position: absolute;
  font-size: 500px;
  top: 0;

  p {
    margin: 0;
    color: transparent;
    line-height: 0.9;

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.15);

    user-select: none;
  }
`

const CreativeHeading = styled(HeadingSecondary)`
  font-size: 70px;
  font-weight: 400;
  margin: 40px 0;
`

const CreativeCopy = styled.div`
  position: relative;
  width: 55%;
  max-width: 1440px;
  margin: 125px 50px;
  text-align: right;
`

const Tagline = styled.div`
  font-size: 44px;

  p {
    margin: 0;
  }

  strong {
    font-weight: 900;
    color: #27aac9;
  }
`

const EvolvContainer = styled.div`
  width: 150px;
  height: 150px;
  position: absolute;
  right: -20%;
  top: -50%;
`

const CreativeAnchor = styled(Anchor)`
  height: auto;
  width: 30%;
  max-width: 550px;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  bottom: 5%;
`

const ContactUs = styled.section`
  background: rgb(11, 17, 24);
  color: #fff;
  padding: 50px 0;
`

/* const HeadingTertiary = styled.h3`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
` */

/* const FeatureDescription = styled.p`
  opacity: 0.8;
` */

const ListStyle = styled(Anchor)`
  height: 20px;
  width: 20px;
  margin-right: 20px;
`

const IndexPage = () => {
  const [on, toggle] = useState(false)

  return (
    <Layout>
      <SEO title="Cutlass Design Studios Home Page" />
      <Header />
      <main>
        <WhoWeAre>
          <Wrapper>
            <EntrepreneurCopy>
              <HeadingSecondary id="who-we-are" className="internal-navigation">
                Enable Creative Entrepreneurs
              </HeadingSecondary>
              <SlideContent displacement="-10%">
                <BlockQuote>
                  It is not the strongest of the species that survives, nor the
                  most intelligent, but the one most responsive to change
                </BlockQuote>
                <p>
                  The fourth revolution is upon us, and every day we see a
                  further blur between <strong>physical</strong> and{" "}
                  <strong>digital</strong>. Business is in a propitious time of
                  change, and the unprecedented <strong>power</strong> of
                  digital has yet to be grasped.
                </p>
                <p>Digital evolution sets way to:</p>
                <ul>
                  <li>
                    <ListStyle /> a stronger economy
                  </li>
                  <li>
                    <ListStyle /> a bigger platform
                  </li>
                  <li>
                    <ListStyle /> a never before seen level of efficiency
                  </li>
                </ul>
              </SlideContent>
            </EntrepreneurCopy>
          </Wrapper>
        </WhoWeAre>
        <WhatWeDo id="what-we-do">
          <CreativeIntelligence>
            <BackgroundScroll />
          </CreativeIntelligence>
          <CreativeCopy>
            <CreativeHeading>
              Creativity Is Intelligence Having Fun.
            </CreativeHeading>
            <Tagline>
              <p onClick={() => toggle(!on)}>Cutlass Design Studios</p>

              <RevealText>
                <span>
                  <strong>creates</strong> unique experiences
                </span>
                <span>
                  <strong>innovates</strong> on design
                </span>
                <span>
                  <strong>perfects</strong> brand identities
                </span>
                <span>
                  <strong>connects</strong> businesses to clients
                </span>
              </RevealText>
            </Tagline>
            <EvolvContainer>
              <Evolv />
            </EvolvContainer>
          </CreativeCopy>

          <CreativeAnchor fillColor="#0f1319" />
        </WhatWeDo>
        <ContactUs id="contact-us">
          <ContactForm />
        </ContactUs>
      </main>
    </Layout>
  )
}

export default IndexPage

/* <ChainContent>
    <Flex justifyContent={"flex-end"} style={{ textAlign: "right" }}>
      <FeatureCard>
        <HeadingTertiary>Create Unique Experiences</HeadingTertiary>
        <FeatureDescription>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Nihil iste autem quo? Numquam, totam! Ipsum vero aut,
          expedita magnam enim saepe id veniam dolore natus aperiam
          atque quae itaque cupiditate.
        </FeatureDescription>
      </FeatureCard>
      <FeatureCard>
        <HeadingTertiary>Innovate On Design</HeadingTertiary>
        <FeatureDescription>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Nihil iste autem quo? Numquam, totam! Ipsum vero aut,
          expedita magnam enim saepe id veniam dolore natus aperiam
          atque quae itaque cupiditate.
        </FeatureDescription>
      </FeatureCard>
      <FeatureCard>
        <HeadingTertiary>Perfect Brand Identities</HeadingTertiary>
        <FeatureDescription>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Nihil iste autem quo? Numquam, totam! Ipsum vero aut,
          expedita magnam enim saepe id veniam dolore natus aperiam
          atque quae itaque cupiditate.
        </FeatureDescription>
      </FeatureCard>
      <FeatureCard>
        <HeadingTertiary>
          Connect Clients To Businesses
        </HeadingTertiary>
        <FeatureDescription>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Nihil iste autem quo? Numquam, totam! Ipsum vero aut,
          expedita magnam enim saepe id veniam dolore natus aperiam
          atque quae itaque cupiditate.
        </FeatureDescription>
      </FeatureCard>
    </Flex>
  </ChainContent> */
