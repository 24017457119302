import React, { useState, FunctionComponent } from "react"
import { motion, Variants } from "framer-motion"
import { Waypoint } from "react-waypoint"

const variants: Variants = {
  on: {
    opacity: 1,

    transition: {
      type: "spring",
      stiffness: 25,
    },
  },
  off: {
    opacity: 0,
  },
}

interface IProps {
  className?: string
}

const FadeContent: FunctionComponent<IProps> = ({ children, className }) => {
  const [on, toggle] = useState(false)

  return (
    <motion.div
      initial={false}
      animate={on ? "on" : "off"}
      variants={variants}
      className={className}
    >
      <Waypoint bottomOffset="20%" onEnter={() => !on && toggle(true)} />
      {children}
    </motion.div>
  )
}

export default FadeContent
