// import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { Canvas } from "react-three-fiber"

import SeaScape from "./SeaScape"
import CutlassLogo from "./Icons/CutlassLogo"

const Heading = styled.header`
  background: rgba(11, 17, 24, 1);
  height: 150vh;

  /* @media (max-width: 900px) {
    height: 100vh;
  } */
  @media (max-width: 600px) {
    height: 100vh;
  }
`

const Container = styled.div`
  /* position: relative; */
  position: sticky;
  top: 0;
  width: 100%;
  /* max-width: 1440px; */
  margin: 0 auto;
  height: 100vh;
`

const Gradient = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    transparent 60%,
    rgba(11, 17, 24, 0.4) 70%,
    rgba(11, 17, 24, 0.8) 80%,
    rgba(11, 17, 24, 1) 90%,
    rgba(11, 17, 24, 1) 100%
  );
  /* box-shadow: 0 -50px 15px 20px rgba(11, 17, 24, 1) inset; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const LogoBox = styled.div`
  text-align: center;
`

const Logo = styled(CutlassLogo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.9;
  z-index: 2;
  text-align: center;
  margin: 0;

  width: 50%;
  max-width: 800px;

  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }

  /* font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1000 - 300))); */
  /* font-size: calc(
    [minimum size]px + ([maximum size] - [minimum size]) *
      (
        (100vw - [minimum viewport width]) /
          ([maximum viewport width] - [minimum viewport width])
      )
  ); */
`

const Header: FunctionComponent = () => (
  <Heading>
    <Container>
      <Canvas
        gl={{
          antialias: false,
        }}
      >
        <SeaScape />
      </Canvas>
      <Gradient />
      <LogoBox>
        <Logo />
      </LogoBox>
    </Container>
  </Heading>
)

export default Header
