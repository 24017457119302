import React, { useRef, FunctionComponent, useEffect } from "react"
import { useFrame } from "react-three-fiber"
import {
  Mesh,
  UniformsUtils,
  ShaderLib,
  Color,
  ShaderMaterial,
  SphereGeometry,
  Vector3,
} from "three"
import glsl from "glslify"
import { useSpring, useTransform } from "framer-motion"

interface IBlobProps {
  position?: Vector3
  isInvalid?: boolean
  isModified?: boolean
}

const customVertexShader = glsl`
  #define PHONG
  uniform float time;
  uniform float amp;
  attribute float offset;
  varying vec3 vViewPosition;

  #pragma glslify: noise = require('glsl-noise/classic/3d');

  #ifndef FLAT_SHADED
    varying vec3 vNormal;
  #endif

  #include <common>
  #include <uv_pars_vertex>
  #include <uv2_pars_vertex>
  #include <displacementmap_pars_vertex>
  #include <envmap_pars_vertex>
  #include <color_pars_vertex>
  #include <fog_pars_vertex>
  #include <morphtarget_pars_vertex>
  #include <skinning_pars_vertex>
  #include <shadowmap_pars_vertex>
  #include <logdepthbuf_pars_vertex>
  #include <clipping_planes_pars_vertex>

  void main() {
    #include <uv_vertex>
    #include <uv2_vertex>
    #include <color_vertex>

    #include <beginnormal_vertex>
    #include <morphnormal_vertex>
    #include <skinbase_vertex>
    #include <skinnormal_vertex>
    #include <defaultnormal_vertex>

    #ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED
      vNormal = normalize( transformedNormal );
    #endif

    #include <begin_vertex>
    #include <morphtarget_vertex>
    #include <skinning_vertex>
    #include <displacementmap_vertex>
    #include <project_vertex>

    // Here starts the custom part of the shader
    // Default Projection
    /* vec4 projectedPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectedPosition; */

    // Blob
    float frequency = 0.45;
    // Pass the altered position vector to the 3d perlin noise function
    float displacement = amp * noise(frequency * position + time);

    // Add the position vector to the altered normal vector
    vec3 newPosition = position + normal * displacement;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
    // Here ends the custom part of the shader

    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>

    vViewPosition = - mvPosition.xyz;

    #include <worldpos_vertex>
    #include <envmap_vertex>
    #include <shadowmap_vertex>
    #include <fog_vertex>
  }`

const Blob: FunctionComponent<IBlobProps> = ({
  position,
  isInvalid,
  isModified,
}) => {
  const timeOffset = useRef(Math.random() * 1000)
  const rotationOffset = useRef({
    x: Math.random() / 100,
    y: Math.random() / 100,
    z: Math.random() / 100,
  })
  const amp = useSpring(0, {
    stiffness: 10,
  })
  const color = useTransform(
    amp,
    [0, 0.2, 0.8],
    [`#aa00ff`, `#aa00ff`, `#cf4647`]
  )

  useEffect(() => {
    if (isInvalid) amp.set(0.8)
    else if (isModified) amp.set(0.2)
    else amp.set(0.0)
  }, [isInvalid, isModified])

  const diffuseColor = useRef(new Color("#aa00ff"))
  const customUniforms = useRef(
    UniformsUtils.merge([
      ShaderLib.phong.uniforms,
      { diffuse: { value: diffuseColor.current } },
      { amp: { value: 0.0 } },
      { time: { value: 0.0 } },
    ])
  )

  const mesh = useRef<Mesh<SphereGeometry>>(null)
  const customMaterial = useRef<ShaderMaterial>(null)

  useFrame(({ clock }) => {
    if (mesh.current) {
      // mesh.current.rotation.x += 0.005
      mesh.current.rotation.x += rotationOffset.current.x
      mesh.current.rotation.y += rotationOffset.current.y
      mesh.current.rotation.z += rotationOffset.current.z
    }

    if (customMaterial.current) {
      customMaterial.current.uniforms.time.value =
        clock.getElapsedTime() + timeOffset.current
      customMaterial.current.uniforms.amp.value = amp.get()
      diffuseColor.current.set(color.get())
      customMaterial.current.uniforms.diffuse.value = diffuseColor.current
    }
  })

  return (
    <mesh ref={mesh} position={position}>
      <sphereGeometry attach="geometry" args={[1, 50, 50]} />
      <shaderMaterial
        ref={customMaterial}
        attach="material"
        color={0x00ff00}
        uniforms={customUniforms.current}
        vertexShader={customVertexShader}
        fragmentShader={ShaderLib.phong.fragmentShader}
        wireframe
        lights
      />
    </mesh>
  )
}

export default Blob
