import React, { useState, FunctionComponent } from "react"
import { motion, Variants } from "framer-motion"
import { Waypoint } from "react-waypoint"

const variants: Variants = {
  on: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 30,
      damping: 100,
    },
  },
  off({ displacement }) {
    return {
      opacity: 0,
      x: displacement,
    }
  },
}

interface IProps {
  displacement: string
  className?: string
}

const SlideContent: FunctionComponent<IProps> = ({
  children,
  displacement,
  className,
}) => {
  const [on, toggle] = useState(false)

  return (
    <motion.div
      initial={false}
      animate={on ? "on" : "off"}
      variants={variants}
      custom={{ displacement }}
      className={className}
    >
      <Waypoint bottomOffset="20%" onEnter={() => !on && toggle(true)} />
      {children}
    </motion.div>
  )
}

export default SlideContent
